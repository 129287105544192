import * as React from "react"

import Seo from "../components/Seo"

const termsPage = () => (
  <>
    <Seo title="Terminos y Condiciones" />
    <div className="terms">
      <h1>
        Términos y condiciones de uso de la página web y servicios de CENHIES
      </h1>
      <blockquote>
        Al hacer uso de esta página usted declara estar de acuerdo con los
        términos y condiciones que aquí se expresan. En caso contrario, le
        solicitamos abandonar la página de internet. Todos los derechos, títulos
        e intereses de este sitio son de la titularidad y propiedad de Centro
        Hidalguense de Estudios Superiores, Sociedad Civil, CENHIES, y ninguno
        de los elementos aquí mencionados puede ser utilizado sin su
        autorización.
      </blockquote>
      <h2>Identificación de las partes</h2>
      <p>
        Por una parte, Centro Hidalguense de Estudios Superiores, Sociedad
        Civil, en adelante CENHIES, declara que:
      </p>
      <ol>
        <li>
          Es una Sociedad Civil debidamente constituida conforme a las leyes de
          los Estados Unidos Mexicanos.
        </li>
        <li>
          Su objeto social le permite obligarse en términos del presente
          documento.
        </li>
        <li>Es titular de los derechos de autor de la página que visita.</li>
      </ol>
      <p>
        Por la otra parte, el Usuario o visitante de la página web propiedad de
        CENHIES, en la red mundial denominada Internet, mediante la mera
        utilización o visita de la página, reconoce y acepta la aplicación de
        los presentes términos y condiciones. Así mismo, declara que:
      </p>
      <ol>
        <li>
          Cuenta con la capacidad y las facultades suficientes para suscribir
          los presentes términos y condiciones.
        </li>
        <li>
          Entiende y acepta el contenido obligacional y alcance legal de los
          presentes términos y condiciones.
        </li>
        <li>
          Ha revisado cuidadosamente las obligaciones que adquiere en los
          presentes términos y condiciones.
        </li>
      </ol>
      <h2>Definiciones</h2>
      <table>
        <tr>
          <td>Aviso de Privacidad de CENHIES</td>
          <td>
            El aviso de privacidad de protección datos personales en posesión de
            los particulares, de acuerdo a lo establecido en la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares
            vigente y que puede ser consultado en la página web de CENHIES
          </td>
        </tr>
        <tr>
          <td>Formularios</td>
          <td>
            Formatos electrónicos en la página web de CENHIES, que formen parte
            de algún programa de cómputo o plataforma ya sea propiedad de
            CENHIES o de algún proveedor o tercero, mediante los cuales el
            usuario registra su información personal y autoriza el uso de los
            mismos para la debida prestación del servicio que da CENHIES, como
            de los proveedores o terceros que prestan los servicios de
            procesamiento de información y pago en línea.
          </td>
        </tr>
        <tr>
          <td>Página web de CENHIES</td>
          <td>
            Es el sitio web mediante la cual el Usuario ingresa, conoce, usa,
            registra o utiliza los servicios que presta CENHIES, o cualquiera de
            sus proveedores o terceros, a través de las{" "}
            <a href="https://www.cenhies.edu.mx">www.cenhies.edu.mx</a>,
            incluyendo los subdominios de las mismas, así como páginas dentro de
            cualquiera de dichos sitios, y cualquier otro equivalente tales
            como: espejo, reemplazo, sustituto o respaldo de dicho sitio web. No
            aplicado a otros sitios web de CENHIES, que especifiquen sus propios
            términos y condiciones de uso.
          </td>
        </tr>
        <tr>
          <td>Proveedor o tercero</td>
          <td>
            Cualquier ente externo ajeno a CENHIES, que preste un servicio
            determinado, incluyendo el procesamiento de pagos en línea, así como
            para el procesamiento de información personal, patrimonial,
            financiera y/o confidencial propiedad del usuario que visita la
            página web de CENHIES.
          </td>
        </tr>
        <tr>
          <td>Servicios de CENHIES</td>
          <td>
            Los Servicios consistentes en impartición de educación a nivel
            Secundaria, Preparatoria, Licenciatura, Posgrados, Maestrías,
            Diplomados y Cursos de Educación Continua, mismos que son dados a
            conocer a través de la página web de CENHIES y sus diferentes medios
            electrónicos.
          </td>
        </tr>
        <tr>
          <td>Servicios de proveedores o terceros</td>
          <td>
            Cualquier servicio prestado a CENHIES y/o a sus usuarios, incluyendo
            los consistentes en procesamiento de información personal del
            usuario, así como el procesamiento de pagos en línea de
            colegiaturas, inscripciones o cualquier otro concepto derivado de la
            prestación del servicio que realiza CENHIES, y que es gestionado por
            un proveedor o tercero.
          </td>
        </tr>
        <tr>
          <td>Usuario</td>
          <td>
            Cualquier persona que ingrese a través de la página web de CENHIES,
            y que mediante el ingreso a la misma, acepte los presentes términos
            y condiciones.
          </td>
        </tr>
      </table>
      <p>
        Las Partes están de acuerdo en que, según lo requiera el contexto, los
        términos antes definidos abarcarán, igualmente, el singular y el plural,
        así como el masculino y el femenino.
      </p>
      <h2>Contenido</h2>
      <h3>Objeto</h3>
      <p>
        El objeto de los presentes términos y condiciones es hacer del
        conocimiento al Usuario de la página web de la que CENHIES es
        propietario y por medio de la cual promociona sus servicios. El Usuario
        podrá usar los Servicios a su discreción, ya sea en ocasiones singulares
        o bien, de manera periódica, en la medida en que así lo desee.
      </p>
      <h3>Excluyente de responsabilidad</h3>
      <p>
        En virtud de que CENHIES y/o cualquiera de sus filiales, partes
        relacionadas, socios y/o empleados no realizan a través de su página web
        ni de ninguna otra, ya sea de manera directa o indirecta, venta,
        distribución, promoción y/o recomendación de ningún tipo de producto y/o
        servicio, o de cualquier otra índole comercial, financiera o de
        negocios, distinta a la promoción de impartición de educación a nivel
        Secundaria, Preparatoria, Licenciaturas, Maestrías, Posgrados,
        Diplomados y Cursos de Educación Continua; CENHIES, no se hace
        responsable de los productos y/o servicios prestados por terceros en
        favor del Usuario, por lo que en este acto el Usuario exime y/o libera
        completa y plenamente de cualquier tipo de responsabilidad legal u
        obligación de cualquier tipo que de cualquier forma pudiera existir o
        interpretarse con relación a la prestación de servicios distintos a los
        ofrecidos por CENHIES.
      </p>
      <p>
        Asimismo la información que llegará a ser proveída por el Usuario no se
        encuentra ni se encontrará de forma alguna verificada, actualizada o
        sancionada por CENHIES y/o cualquiera de sus socios comerciales, por
        tanto, CENHIES no se hace, ni se hará responsable en momento alguno
        respecto de la información que provea el Usuario (la cual la hará a su
        más entera y discreta responsabilidad), a través del uso de formularios
        de programas o plataformas propiedad de CENHIES en su página web, por lo
        que en este acto el Usuario exime y/o libera completa y plenamente de
        cualquier tipo de responsabilidad legal u obligación de cualquier tipo
        que de cualquier forma pudiera existir o interpretarse con relación al
        uso del contenido de la página web de CENHIES.
      </p>
      <p>
        Asimismo, en términos del párrafo anterior, el usuario libera de
        cualquier responsabilidad a CENHIES, del manejo de la información
        personal, financiera o patrimonial, que el usuario cargue por decisión
        propia en cualquier formulario ya sea manualmente o a través de
        aplicaciones, herramientas, plataformas o programas de cómputo de
        proveedores o terceros, siendo bajo su más estricta responsabilidad la
        información cargada y compartida por cualquier medio. Por tanto, el
        usuario al abandonar la página web de CENHIES, entiende y acepta que su
        información personal (Datos Personales, Datos Patrimoniales, Datos
        Financieros, Información Confidencial y/o información académica) serán
        compartidos por él mismo con terceros que prestan los servicios de
        recepción de datos, procesamiento de datos, así como el procesamiento de
        pagos, por lo que es su más estricta responsabilidad antes de compartir
        dicha información, el conocer tanto sus políticas de privacidad de la
        información como sus políticas de cargos, procesamientos, devoluciones
        de pago y/o cualquier otro, según sea el caso.
      </p>
      <h3>Comunicación</h3>
      <p>
        Al ingresar a la página web de CENHIES y/o al cargar información
        personal en la misma con el fin de utilizar los servicios de CENHIES y/o
        para utilizar el servicio de pago en línea y/o cualquier otro, el
        Usuario acepta recibir algunas comunicaciones electrónicas. Estas
        comunicaciones pueden incluir, sin limitar, mensajes de correo
        electrónico que el usuario proporcionó en los formularios, mensajes de
        texto (SMS) al número telefónico que el Usuario proporcionó en los
        formularios, publicaciones en la página web de CENHIES, entre otras.
        Acuerda también que todo aviso, acuerdo, divulgación u otra comunicación
        que le sea enviada en forma electrónica cumplirá con los requisitos
        legales aplicables.
      </p>
      <h3>Inicio de la vigencia de los presentes términos y condiciones</h3>
      <p>
        Los presentes términos y condiciones surten efectos a partir del momento
        de la aceptación del Usuario, es decir, al momento que ingresa a la
        página web de CENHIES acepta los presentes términos y condiciones.
      </p>
      <h3>Veracidad de la información</h3>
      <p>
        El usuario es responsable de mantener la veracidad y la exactitud de la
        información personal, financiera, patrimonial y/o confidencial que en su
        caso ingrese a la página web de CENHIES, que comparta con CENHIES, o con
        cualquier otro proveedor o tercero de manera física y/o digital.
      </p>
      <h3>Suspensión de la página</h3>
      <p>
        CENHIES se reserva el derecho de suspender, por cualquier causa, la
        página web de CENHIES en cualquier momento, con o sin aviso previo a los
        usuarios.
      </p>
      <h3>Cancelación de la página</h3>
      <p>
        CENHIES se reserva el derecho de cancelar, por cualquier causa, la
        página web de CENHIES, en cualquier momento, con o sin aviso previo a
        los usuarios.
      </p>
      <h3>Propiedad intelectual</h3>
      <p>
        La página web de CENHIES es propiedad exclusiva de CENHIES. La
        utilización del servicio y del contenido de la página, en todo momento
        se regirá y estarán sujetos a las leyes relativas de Derechos de Autor y
        el uso de la Propiedad Intelectual, salvo que expresamente se
        especifique otra cosa. CENHIES no confiere al adquirente ningún derecho
        de alteración, explotación, reproducción, distribución o comunicación
        pública sobre la página web de CENHIES, reservándose todos estos
        derechos. La cesión de los citados derechos precisará el previo
        consentimiento por escrito por parte del titular de los mismos, de
        manera que el/los usuario(s) no podrá(n) poner a disposición de terceras
        personas dichos contenidos. La propiedad intelectual se extiende, además
        del contenido incluido en la página web de CENHIES, a sus gráficos,
        logotipos, diseños, imágenes y códigos fuente utilizados para su
        programación, por lo que nada de lo contenido en él debe entenderse como
        la concesión de una licencia para usar cualquiera de las marcas
        registradas, o cualquier otro derecho de propiedad intelectual o de
        derecho de autor.
      </p>
      <h3>Prohibiciones o restricciones</h3>
      <p>
        El usuario no podrá usar los Contenidos de la página web de CENHIES, en
        sitios o páginas de su propiedad o ajenas, ni tendrá el derecho de
        colocar enlaces y/o ligas de y hacia la página web de CENHIES, si no
        cuenta con la autorización respectiva y por escrito de CENHIES,
        asimismo, el usuario no tendrá el derecho de limitar o impedir a
        cualquier otro usuario el uso de la página web de CENHIES.
      </p>
      <h3>Nulidad e inexistencia</h3>
      <p>
        Si alguna disposición de este acuerdo se encontrare prohibida por alguna
        ley aplicable o es declarada nula o inexigible en alguna jurisdicción,
        dicha disposición se considerará nula en la medida en que esté prohibida
        o sea inexigible pero no afectará, modificará o dará lugar a la nulidad
        del resto de las disposiciones de estos términos y condiciones o la
        exigibilidad de las mismas.
      </p>
      <h3>Acuerdo íntegro</h3>
      <p>
        Los presentes términos y condiciones constituyen el acuerdo íntegro de
        las partes con respecto a los asuntos previstos en los mismos y deja sin
        efecto cualquier contrato escrito o verbal celebrado por las partes con
        anterioridad, incluyendo, de manera enunciativa pero no limitativa,
        cualquier carta de intención o acuerdo en principio.
      </p>
      <h3>Modificaciones</h3>
      <p>
        CENHIES y/o cualquiera de sus socios comerciales, se reservan el derecho
        de modificar los presentes términos y condiciones cuando así lo estimen
        necesario, debiendo notificar sobre dichos cambios al Usuario de manera
        oportuna publicándolos en la(s) página(s) web de CENHIES y/o mediante
        correo electrónico en la dirección que señale para tales efectos en los
        formularios.
      </p>
      <h3>Contenidos y servicios</h3>
      <p>
        CENHIES no garantizará la disponibilidad de la operación de la página
        web de CENHIES, sus contenidos, secuencia, ni la utilidad de estos, en
        relación con cualquier actividad, por lo que se deslinda expresamente de
        cualquier responsabilidad por daño o pérdida alguna derivada de errores
        u omisiones en la información contenida en la página web de CENHIES, así
        como de los cambios o mejoras que se realicen periódicamente a su
        contenido y servicio, independientemente del medio de acceso que utilice
        el Usuario, incluido la telefonía móvil o celular.
      </p>
      <h3>Privacidad</h3>
      <p>
        CENHIES, garantizará un tratamiento correcto a los datos personales del
        usuario con respecto a los servicios que presta, siempre y cuando el
        usuario haga entrega de estos a CENHIES, para mayor información
        consultar el aviso de privacidad de protección de datos personales en
        posesión de los particulares, a través de la página web de CENHIES.
      </p>
      <p>
        Para los servicios de proveedores o terceros externos a CENHIES, como lo
        es el servicio de pago en línea y el procesamiento de la información, el
        usuario se obliga a consultar la política de privacidad de dichos
        proveedores, en su respectiva página de internet o por los medios que el
        proveedor o el tercero le indiquen. CENHIES no será responsable de la
        omisión por parte del usuario con respecto al desconocimiento de las
        políticas de privacidad, ni tampoco de los servicios que preste
        cualquier otro proveedor o tercero.
      </p>
      <h3>Reglas de interpretación</h3>
      <p>
        Las Partes están de acuerdo, salvo que el contexto requiera lo
        contrario, se interpretará de conformidad con las siguientes reglas, por
        ser acordes éstas al motivo determinante de su voluntad:
      </p>
      <ol type="a">
        <li>
          Los encabezados de los apartados y capítulos de este documento se
          establecen para efectos meramente de referencia, por lo que no limitan
          en forma alguna su contenido y alcance, debiéndose estar en todo caso
          a lo pactado por las Partes en el contenido de que se trate.
        </li>
        <li>
          Las definiciones, declaraciones y contenidos manifestados en el
          presente documento obligarán a las Partes.
        </li>
        <li>
          Las referencias que se hacen al presente documento, incluye sus
          Anexos, modificaciones y cualquier otro documento de la operación.
        </li>
        <li>
          La palabra “incluye” o “incluyendo”, se entenderá como “incluyendo,
          sin limitar”, y no se interpretará en el sentido de que los ejemplos
          dados son una lista exhaustiva de los temas tratados.
        </li>
        <li>El singular incluye el plural y viceversa.</li>
        <li>El masculino incluye el femenino y viceversa.</li>
        <li>
          El presente documento y sus Anexos se deberán interpretar conforme el
          Principio de Autonomía de la Voluntad de las Partes, propio del
          Derecho Civil, buscando siempre, en lo conducente, la prevalencia del
          referido Principio.
        </li>
      </ol>
      <h3>Jurisdicción y ley aplicable</h3>
      <p>
        Los presentes términos y condiciones se regirán e interpretarán de
        conformidad con las leyes aplicables en la ciudad de Pachuca, Hidalgo,
        México, y para cualquier controversia relacionada con éstos términos y
        condiciones, las Partes se someten a la jurisdicción única y exclusiva
        de los tribunales competentes de la ciudad de Pachuca, Hidalgo, México,
        por lo que expresamente renuncian a cualquier otra jurisdicción que
        pudiere corresponderles por razón de su domicilio presente o futuro o
        por cualquier otra causa.
      </p>
      <blockquote>
        Habiendo sido leído por las partes el presente acuerdo y sabedoras de su
        alcance legal, el Usuario manifiesta la aceptación de los presentes
        términos y condiciones, al ejecutar la página web de CENHIES.
      </blockquote>
      <b>
        D.R. © 2022, Centro Hidalguense de Estudios Superiores, S.C. (CENHIES)
        Boulevard Luis Donaldo Colosio 101, Colonia Ampliación Santa Julia,
        Pachuca De Soto, Hidalgo, México.
      </b>
    </div>
  </>
)

export default termsPage
